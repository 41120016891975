import React from 'react';
import bemCn from 'bem-cn';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import { RouteFormatter } from '../../routes';
import { BLOG_CATEGORIES } from '../../consts/blog.consts';
import PictureSet from '../picture-set';
import dogT1 from '../../assets/img/dog-trainers/dt-usa.png';
import dogT2 from '../../assets/img/dog-trainers/dt-seattle.png';
import dogT3 from '../../assets/img/dog-trainers/dt-portland.png';
import dogT4 from '../../assets/img/dog-trainers/dt-la.png';
import dogT5 from '../../assets/img/dog-trainers/dt-ny.png';
import dog1 from '../../assets/img/dog-names/dog.jpg';
import dog2 from '../../assets/img/dog-names/ml-dog.jpg';
import dog3 from '../../assets/img/dog-names/fm-dog.jpg';
import dog4 from '../../assets/img/dog-names/gl-ret.jpg';
import dog5 from '../../assets/img/dog-names/lb-ret.jpeg';
import './style.scss';

const b = bemCn('blog-categories-box');

const today = new Date();
const thisYear = today.getFullYear();

const dogNamesPosts = [
    {
        descriptionShort:
            'Looking for the perfect dog name for your new pup? We have created filterable lists of dog names from our database of hundreds of thousands of Sniffspot users. You can filter by gender, breed and state to find the most cute, unique and creative dog names.',
        slug: '/dog-names',
        thumbnail: dog1,
        title: 'Top 1,000 Most Popular Dog Names',
        updatedAt: '2024-05-12T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'Looking for the perfect dog name for your new male pup? We have created filterable lists of male dog names from our database of hundreds of thousands of Sniffspot users. You can filter by gender, breed and state to find the most cute, unique and creative male dog names.',
        slug: '/dog-names/male',
        thumbnail: dog2,
        title: 'Most Popular Male Dog Names',
        updatedAt: '2024-05-12T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'Looking for the perfect dog name for your new female pup? We have created filterable lists of female dog names from our database of hundreds of thousands of Sniffspot users. You can filter by gender, breed and state to find the most cute, unique and creative female dog names.',
        slug: '/dog-names/female',
        thumbnail: dog3,
        title: 'Most Popular Female Dog Names',
        updatedAt: '2024-05-12T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'Welcome to our comprehensive list of Golden Retriever dog names, curated from our vast database of Sniffspot users. Filter through hundreds of thousands of options by gender, breed, and state to discover the most adorable, original, and imaginative names for your beloved Golden Retriever.',
        slug: '/dog-names/golden-retriever',
        thumbnail: dog4,
        title: 'Most Popular Golden Retriever Names',
        updatedAt: '2024-05-12T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            "Welcome to our Labrador Retriever dog names page! Here you can browse through filterable lists of names for your beloved furry friend, ranging from cute and classic to unique and creative options. Our database of hundreds of thousands of Sniffspot users ensures you'll find the perfect name for your Labrador Retriever, whether you're seeking a name for a male or female, based on breed or state.",
        slug: '/dog-names/labrador-retriever',
        thumbnail: dog5,
        title: 'Most Popular Labrador Retriever Names',
        updatedAt: '2024-05-12T08:51:33.000-08:00',
    },
];

const dogTrainersPosts = [
    {
        descriptionShort:
            'This is a list of the top dog trainers in the United States, based on votes from the Sniffspot community and the general public.',
        slug: '/blog/dog-trainers/united-states',
        thumbnail: dogT1,
        title: `The Best Dog Trainers in the United States of ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'This is a list of the top dog trainers in Seattle, WA, based on votes from the Sniffspot community and the general public.',
        slug: '/blog/dog-trainers/seattle-wa',
        thumbnail: dogT2,
        title: `The Best Dog Trainers in Seattle, WA of ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'This is a list of the top dog trainers in Portland, OR, based on votes from the Sniffspot community and the general public.',
        slug: '/blog/dog-trainers/portland-or',
        thumbnail: dogT3,
        title: `The Best Dog Trainers in Portland, OR of ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'This is a list of the top dog trainers in Los Angeles, CA, based on votes from the Sniffspot community and the general public.',
        slug: '/blog/dog-trainers/los-angeles-ca',
        thumbnail: dogT4,
        title: `The Best Dog Trainers in Los Angeles, CA of ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'This is a list of the top dog trainers in New York, NY, based on votes from the Sniffspot community and the general public.',
        slug: '/blog/dog-trainers/new-york-ny',
        thumbnail: dogT5,
        title: `The Best Dog Trainers in New York, NY of ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
];

const dogRescuesPosts = [
    {
        descriptionShort:
            'This list showcases the top dog rescues & shelters in Washington. These remarkable organizations have been recognized for their unwavering dedication to the well-being of countless dogs.',
        slug: '/dog-rescues/washington',
        thumbnail: 'https://data.sniffspot.com/Photos/SeoDogRescue/1282/178507e67e44-MC_logo_square.jpg',
        title: `The Best Washington Dog Rescues & Shelters in ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'This list showcases the top dog rescues & shelters in Oregon. These remarkable organizations have been recognized for their unwavering dedication to the well-being of countless dogs.',
        slug: '/dog-rescues/oregon',
        thumbnail: 'https://data.sniffspot.com/Photos/SeoDogRescue/931/cef8bbb17769-South_Coast_Humane_Society.png',
        title: `The Best Oregon Dog Rescues & Shelters in ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'This list showcases the top dog rescues & shelters in California. These remarkable organizations have been recognized for their unwavering dedication to the well-being of countless dogs.',
        slug: '/dog-rescues/california',
        thumbnail: 'https://data.sniffspot.com/Photos/SeoDogRescue/61/30aea3371b69-MainSquare_2.png',
        title: `The Best California Dog Rescues & Shelters in ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'This list showcases the top dog rescues & shelters in Florida. These remarkable organizations have been recognized for their unwavering dedication to the well-being of countless dogs.',
        slug: '/dog-rescues/florida',
        thumbnail: 'https://data.sniffspot.com/Photos/SeoDogRescue/298/1000035238.jpg',
        title: `The Best Florida Dog Rescues & Shelters in ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
    {
        descriptionShort:
            'This list showcases the top dog rescues & shelters in New York. These remarkable organizations have been recognized for their unwavering dedication to the well-being of countless dogs.',
        slug: '/dog-rescues/new-york',
        thumbnail: 'https://data.sniffspot.com/Photos/SeoDogRescue/814/91cfd62cd986-IMG_1035.jpeg',
        title: `The Best New York Dog Rescues & Shelters in ${thisYear}`,
        updatedAt: '2024-07-10T08:51:33.000-08:00',
    },
];

const BlogCategories = ({ categories = [], chosenSlug }) => {
    if (!categories || categories.length < 1) {
        return '';
    }

    let myCat = categories.slice(1);

    if (chosenSlug) {
        myCat = myCat.filter((c) => c.slug != chosenSlug);
    }

    return (
        <div className={b()}>
            {myCat.map((c, i) => {
                const blogCat = BLOG_CATEGORIES[c.slug];
                const isDogNames = c.slug == 'dog-names';
                const isDogTrainers = c.slug == 'dog-trainers';
                const isDogRescues = c.slug == 'dog-rescues/united-states';

                if (!blogCat && !isDogNames && !isDogRescues) {
                    return '';
                }

                let title = blogCat?.contentName;
                let staticPosts = c.staticPosts;
                let slug = RouteFormatter.blogCategory({ id: blogCat?.slug || '' });

                if (isDogNames) {
                    title = 'Top dog names in the US';
                    staticPosts = dogNamesPosts;
                    slug = RouteFormatter.dogNames();
                } else if (isDogRescues) {
                    title = 'Top dog rescues in the US';
                    staticPosts = dogRescuesPosts;
                    slug = RouteFormatter.dogRescue();
                } else if (isDogTrainers) {
                    staticPosts = dogTrainersPosts;
                }

                return (
                    <div key={i}>
                        <a href={slug} className={b('title')}>
                            <h3>{title}</h3>
                        </a>
                        <ul className={b('posts-box')}>
                            {staticPosts.map((po, id) => {
                                const altImg = `${po.title} thumbnail`;
                                const srcImg = ImageHelper.getUrlFromOptions(po.thumbnail, IMG_RESIZES.x354);
                                const postSlug = po.slug;

                                const handleClick = (e) => {
                                    e.preventDefault();
                                    window.location = postSlug;
                                };

                                return (
                                    <li key={id} className={b('post').mix('c-pointer')} onClick={handleClick}>
                                        <PictureSet imgs={srcImg} width={354} height={256} alt={altImg} />
                                        <h4 className="snif-p snif-m1-pc snif-semibold text-capitalize mt-md-3 mb-md-2">
                                            <a href={postSlug}>{po.title}</a>
                                        </h4>
                                        <div className={b('desc')} dangerouslySetInnerHTML={{ __html: po.descriptionShort }} />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                );
            })}
        </div>
    );
};

export default BlogCategories;
