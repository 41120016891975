import React from 'react';
import { LISTING_FAQS_CONTENT } from './listings-faq.consts';
import SvgAmenitiesDefault from 'src/assets/svg/amenities/default.svg';
import SvgDog from 'src/assets/svg/SvgDog.svg';
import SvgDogLeash from 'src/assets/svg/SvgDogLeash.svg';
import SvgHandshake from 'src/assets/svg/SvgHandshake.svg';
import SvgPin from 'src/assets/svg/SvgPin.svg';
import SvgTopBadgeColor from 'src/assets/svg/SvgTopBadgeColor.svg';

export const BLOG_CATEGORIES = {
    'dog-training': {
        name: 'Dog Training',
        contentName: 'Dog training guides',
        slug: 'dog-training',
        Icon: <SvgDogLeash />,
        bgColor: '#444EA4',
        faqs: [
            {
                que: 'What is positive reinforcement training, and how is it different from aversive training?',
                ans: () => (
                    <>
                        Positive reinforcement training is a method of dog training that involves rewarding good
                        behavior rather than punishing bad behavior. This technique is based on the idea that dogs are
                        more likely to repeat behavior that is rewarded than behavior that is punished. Aversive
                        training, on the other hand, uses punishment such as shock collars, choke chains, or physical
                        force to stop unwanted behavior. Positive reinforcement training is more effective in the long
                        term, as it strengthens the bond between the dog and the owner and builds trust and confidence
                        in the dog.
                    </>
                ),
            },
            {
                que: 'How do I train my dog to come when called?',
                ans: () => (
                    <>
                        The best way to train your dog to come when called is to use positive reinforcement techniques.
                        Start by calling your dog's name and rewarding them with a treat when they come to you. Repeat
                        this process several times, gradually increasing the distance between you and your dog. You can
                        also use a long line to ensure your dog comes back to you. Never call your dog to you if you
                        plan to punish them. This will create a negative association and make your dog less likely to
                        come when called in the future.
                    </>
                ),
            },
            {
                que: 'How can I prevent my dog from jumping on people?',
                ans: () => (
                    <>
                        To prevent your dog from jumping on people, you can use positive reinforcement training to teach
                        them an alternative behavior, such as sitting. Whenever your dog jumps on someone, calmly remove
                        them from the situation and ask them to sit. When your dog sits, reward them with a treat or
                        praise. Consistency is key to training your dog, so make sure everyone who interacts with your
                        dog is on board with this method.
                    </>
                ),
            },
            {
                que: 'What is clicker training, and how can I use it to train my dog?',
                ans: () => (
                    <>
                        Clicker training is a form of positive reinforcement training that uses a clicker to mark
                        desirable behavior. The clicker is a small, handheld device that makes a distinct sound when
                        pressed. Clicker training is based on the principle of operant conditioning, where the dog
                        learns that the sound of the clicker is associated with a reward. To use clicker training, you
                        first need to associate the sound of the clicker with a reward, such as a treat. Then, you can
                        use the clicker to mark good behavior, such as sitting or staying. When the dog hears the click,
                        they know that they have done something right and will be rewarded.
                    </>
                ),
            },
            {
                que: 'How can I train my dog not to bark excessively?',
                ans: () => (
                    <>
                        Excessive barking can be a problem for both the dog and the owner. To train your dog not to bark
                        excessively, you can use positive reinforcement techniques to reward them for being quiet. Start
                        by identifying the triggers that cause your dog to bark, such as the doorbell or other dogs.
                        Then, work with your dog to desensitize them to these triggers by gradually exposing them to the
                        trigger and rewarding them for staying quiet. You can also teach your dog a "quiet" command,
                        which tells them to stop barking.
                    </>
                ),
            },
            {
                que: 'Is it possible to train an older dog?',
                ans: () => (
                    <>
                        Yes, it is possible to train an older dog. While it may take longer for an older dog to learn
                        new behaviors, positive reinforcement techniques can be effective at any age. It's important to
                        remember that older dogs may have physical limitations, so be patient and adjust your training
                        methods accordingly.
                    </>
                ),
            },
            {
                que: 'What should I do if my dog has behavior problems?',
                ans: () => (
                    <>
                        If your dog has behavior problems, the first step is to consult with a professional dog trainer
                        or behaviorist. They can help you identify the underlying cause of the behavior and develop a
                        training plan that works for you and your dog.
                    </>
                ),
            },
        ],
    },
    'dog-reactivity': {
        name: 'Dog Reactivity',
        contentName: 'Dog reactivity guides',
        slug: 'dog-reactivity',
        Icon: <SvgDog />,
        bgColor: '#BC8543',
        faqs: [
            {
                que: 'What is dog reactivity?',
                ans: () => (
                    <>
                        Dog reactivity refers to a dog's tendency to overreact or display an intense response to certain
                        stimuli, such as other dogs, people, or objects. This behavior can manifest in different ways,
                        including barking, lunging, growling, or even biting.
                    </>
                ),
            },
            {
                que: 'What causes dog reactivity?',
                ans: () => (
                    <>
                        There are various factors that can contribute to dog reactivity, including genetics,
                        socialization, past experiences, and health issues. Lack of socialization during the critical
                        period of a dog's development, traumatic experiences, and pain or discomfort can also play a
                        role.
                    </>
                ),
            },
            {
                que: 'Can dog reactivity be treated?',
                ans: () => (
                    <>
                        Yes, dog reactivity can be treated with proper training and behavior modification techniques.
                        The goal is to help the dog feel more comfortable and less anxious around the triggers that set
                        off their reactivity. Positive reinforcement training, counterconditioning, and medication can
                        all be part of a comprehensive treatment plan.
                    </>
                ),
            },
            {
                que: 'Is dog reactivity a form of aggression?',
                ans: () => (
                    <>
                        Dog reactivity is not always a sign of aggression, but it can lead to aggressive behavior if not
                        addressed. Reactive dogs are often anxious or fearful and may lash out if they feel threatened
                        or overwhelmed. It's essential to address dog reactivity early on to prevent aggressive behavior
                        from developing.
                    </>
                ),
            },
            {
                que: 'Can dog reactivity be prevented?',
                ans: () => (
                    <>
                        While some dogs may be predisposed to reactivity due to genetics, early and consistent
                        socialization can help prevent or reduce the severity of dog reactivity. Regular exposure to
                        different people, animals, and environments during the critical period of a dog's development
                        can help them learn to feel safe and comfortable in a variety of situations.
                    </>
                ),
            },
            {
                que: 'What should I do if my dog is reactive?',
                ans: () => (
                    <>
                        If you suspect your dog is reactive, it's important to seek the help of a professional dog
                        trainer or behaviorist. They can assess your dog's behavior and develop a customized treatment
                        plan.
                    </>
                ),
            },
        ],
    },
    'dog-enrichment': {
        name: 'Dog Enrichment',
        contentName: 'Dog enrichment guides',
        slug: 'dog-enrichment',
        Icon: <SvgAmenitiesDefault />,
        bgColor: '#4F96C4',
        faqs: [
            {
                que: 'What is dog enrichment, and why is it important?',
                ans: () => (
                    <>
                        Dog enrichment refers to any activity or item that stimulates your dog mentally or physically,
                        providing them with mental and physical exercise, entertainment, and engagement. Enrichment is
                        essential for a dog's overall well-being, as it can help prevent boredom, reduce stress, and
                        improve behavior.
                    </>
                ),
            },
            {
                que: 'What are some examples of dog enrichment activities?',
                ans: () => (
                    <>
                        There are many ways to provide enrichment for your dog, such as playing interactive games,
                        providing puzzle toys, giving them access to nature, training, socializing, and more. You can
                        also enrich your dog's environment by creating a safe and stimulating space for them to explore
                        and relax in.
                    </>
                ),
            },
            {
                que: 'Can dog enrichment help with behavior issues like reactivity?',
                ans: () => (
                    <>
                        Yes, enrichment can help prevent and reduce behavior issues like reactivity, anxiety, and
                        aggression. By providing your dog with physical and mental stimulation, you can help them
                        channel their energy and reduce their stress levels, which can improve their overall behavior.
                    </>
                ),
            },
            {
                que: 'What are some common signs of dog reactivity?',
                ans: () => (
                    <>
                        Dog reactivity refers to a dog's tendency to react negatively to certain stimuli, such as other
                        dogs, people, or objects. Some common signs of dog reactivity include barking, growling,
                        lunging, or biting when encountering a trigger. Other signs may include raised hackles, a stiff
                        body posture, or an intense stare.
                    </>
                ),
            },
            {
                que: 'How can I help my reactive dog?',
                ans: () => (
                    <>
                        If your dog is reactive, it's important to seek professional help from a veterinarian or
                        certified dog behaviorist. They can help you develop a behavior modification plan that includes
                        positive reinforcement, and counter-conditioning techniques to help your dog overcome their
                        reactivity.
                    </>
                ),
            },
            {
                que: 'Can enrichment help with dog reactivity?',
                ans: () => (
                    <>
                        Yes, providing your dog with enrichment can help reduce their reactivity by providing them with
                        a positive outlet for their energy and reducing their stress levels. Enrichment can also help
                        your dog build confidence and improve their overall behavior.
                    </>
                ),
            },
            {
                que: 'What are some enrichment activities for reactive dogs?',
                ans: () => (
                    <>
                        Enrichment activities for reactive dogs should be designed to provide them with mental and
                        physical stimulation while also helping them feel safe and relaxed. Some examples of enrichment
                        activities for reactive dogs include scent work, puzzle toys, interactive games, training
                        exercises, and slow-paced walks in quiet, peaceful environments.
                    </>
                ),
            },
        ],
    },
    'sniffspot-community': {
        name: 'Sniffspot Community',
        contentName: 'Sniffspot community guides',
        slug: 'sniffspot-community',
        Icon: <SvgHandshake />,
        bgColor: '#3AA648',
        faqs: LISTING_FAQS_CONTENT,
    },
    'dog-trainers': {
        name: 'Top Dog Trainers',
        contentName: 'Top dog trainers in the US',
        slug: 'dog-trainers/united-states',
        Icon: <SvgTopBadgeColor />,
        bgColor: '#6E44A4',
    },
    'city-dog-parks': {
        name: 'City Dog Parks',
        contentName: 'City dog parks guides',
        slug: 'city-dog-parks',
        Icon: <SvgPin />,
        bgColor: '#1690A0',
        faqs: [
            {
                que: 'What is a city dog park, and why are they popular?',
                ans: () => (
                    <>
                        A city dog park is a designated area within a city where dogs can run off-leash and socialize
                        with other dogs. These parks have become increasingly popular as more people are moving to urban
                        areas and are looking for convenient places to exercise their dogs.
                    </>
                ),
            },
            {
                que: 'Are city dog parks safe for my dog?',
                ans: () => (
                    <>
                        City dog parks can be safer if they are well-maintained and properly managed, but they often
                        have safety issues. It's important to research the park before visiting and to pay attention to
                        any posted rules or warnings. Always supervise your dog while they are in the park and make sure
                        they are up-to-date on their vaccinations.
                    </>
                ),
            },
            {
                que: 'How can I ensure my dog stays healthy while visiting city dog parks?',
                ans: () => (
                    <>
                        To keep your dog healthy while visiting city dog parks, make sure they are up-to-date on their
                        vaccinations, including their rabies vaccine. Avoid visiting the park during peak hours when
                        it's crowded, and always bring plenty of water for your dog to drink. Watch for any signs of
                        illness or injury, and seek veterinary care if necessary.
                    </>
                ),
            },
            {
                que: 'Are there any rules I need to follow when visiting a city dog park?',
                ans: () => (
                    <>
                        Yes, each city dog park may have its own set of rules and regulations, such as requiring dogs to
                        be on-leash when entering and exiting the park or prohibiting certain types of behavior like
                        digging. Always read and follow posted signs and rules to ensure a safe and enjoyable experience
                        for everyone.
                    </>
                ),
            },
            {
                que: 'What are some common health risks associated with city dog parks?',
                ans: () => (
                    <>
                        Some common health risks associated with city dog parks include exposure to fleas, ticks, and
                        other parasites.
                    </>
                ),
            },
        ],
    },
};

export const BLOG_LIMIT_POSTS = 6;
export const BLOG_HOME_DEF_POSTS = 11;
export const BLOG_CATE_DEF_POSTS = 16;
export const BLOG_POST_DEF_POSTS = 6;
